<template>
  <v-container>
    <v-card
      :loading="loading"
      class="mx-auto"
      max-width="374"
      elevation="0"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-center deep-purple--text text--lighten-2">
            <v-btn style="margin-bottom: 8px" icon @click.prevent="dialog2 = true"><v-icon>mdi-note-edit-outline</v-icon></v-btn> <b>{{ firstName }} {{ lastName }} {{ phone }}</b>
          </v-list-item-title>
          <v-list-item-subtitle></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-row justify="center">
        <v-dialog
          v-model="dialog2"
          max-width="290"
        >
          <v-card>
            <v-card-text class="text-center pt-4"><h3>Изменить данные</h3></v-card-text>
            <v-card-subtitle class="text-center">
              Чтобы изменить свои учетные данные отредактируйте форму и нажмите кнопку "Сохранить"
            </v-card-subtitle>
            <v-form v-model="valid" ref="form" style="margin-top: -30px">
              <v-container fluid>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="firstName"
                      color="deep-purple lighten-2"
                      label="Имя"
                      prepend-icon="mdi-account"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="lastName"
                      color="deep-purple lighten-2"
                      label="Фамилия"
                      prepend-icon="mdi-account"
                    ></v-text-field>
                  </v-col>

                </v-row>
              </v-container>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="dialog2 = false">
                Отмена
              </v-btn>
              <v-btn color="green darken-1" text @click="editHandler">
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row justify="center" style="margin-top: -30px">
        <v-col cols="auto" sm="12">
          <v-card-text class="text-center" v-if="records.length && !loading">
            Вы записаны на
          </v-card-text>
          <v-card-text class="text-center" v-else-if="!records.length && !loading">
            Вы пока никуда не записаны
          </v-card-text>
        </v-col>
      </v-row>

      <v-row v-if="!loading">
        <v-list-item
          three-line
          v-for="record in records"
          :key="record._id"
          class="pl-6 pt-2 mb10 elevation-3 rounded"
        >
          <v-list-item-content>
            <v-list-item-title class="deep-purple--text text--lighten-2">
              {{ record.date | date }} <b>{{ record.time }}</b>
            </v-list-item-title>

            <v-list-item-subtitle class="deep-purple--text"
                                  v-if="record.firstName && record.lastName
              && (record.firstName !== record.subscriber.firstName || record.lastName !== record.subscriber.lastName)"
            >
              <b>Вы записаны как: {{ record.firstName }} {{ record.lastName }}</b>
            </v-list-item-subtitle>

            <v-card-text>
              <span><b>Услуга: </b>{{ record.category.title }}</span>
              <br>
              <span><b>Процедуры: </b>{{ record.services.map(s => s.title).join(", ") }}</span>
              <br>
              <span><b>Мастер: </b>{{ record.owner.firstName }} {{ record.owner.lastName }} ({{
                  record.owner.phone
                }})</span>
              <br>
              <span><b>Предварительная стоимость: </b>{{ record.total }} руб</span>
              <br>
              <span v-if="record.comment"><b class="pink--text">Коментарий:</b> <i>{{ record.comment }}</i></span>
            </v-card-text>

            <v-row
              v-if="!timeIsOut(record.date, record.time)"
              justify="end"
            >
              <v-btn @click="dialogHandler(record)"
                     text color="deep-purple lighten-2"
              >
                Отменить запись
              </v-btn>
            </v-row>

          </v-list-item-content>
        </v-list-item>

        <v-dialog
          v-model="dialog"
          max-width="290"
        >
          <v-card>
            <v-card-text class="text-center pt-4">
              <h2>Вы уверены?</h2>
              После нажатия кнопки "ДА" запись к мастеру на
              <b v-if="recordToCancel">{{ recordToCancel.date | date }} {{ recordToCancel.time }}</b>
              будет отменена, о чем мастер получит СМС-уведомление.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="dialog = false"
              >
                Назад
              </v-btn>
              <v-btn
                color="error"
                text
                @click="cancelRecord"
              >
                Да, отменить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <NotificationRequest/>
    </v-card>
  </v-container>
</template>

<script>
import NotificationRequest from "@/components/NotificationRequest"


export default {
  name: "ClientRecords",
  components: { NotificationRequest },

  data: () => ({
    records: [],
    loading: true,
    firstName: "",
    lastName: "",
    phone: "",
    valid: true,
    dialog: false,
    recordToCancel: "",
    dialog2: false
  }),

  methods: {
    async loadRecordList() {
      this.loading = true
      const token = JSON.parse(localStorage.getItem("token"))

      const response = await fetch(`${this.$store.state.server}/record/load-record-list-for-client`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${token}`
        },
      })

      const json = await response.json()
      if (response.status === 200) {
        this.records = json.records
      } else if (json.message) {
        this.$store.commit("setServerMessage", json.message)
        this.loading = false
        return
      }

      this.records.sort((r1, r2) => {
        if (r1.date < r2.date) return -1
        if (r1.date > r2.date) return 1
        if (r1.date === r2.date) {
          if (r1.time < r2.time) return -1
          if (r1.time > r2.time) return 1
          return 0
        }
      })

      this.loading = false
    },

    dialogHandler(record) {
      this.recordToCancel = record
      this.dialog = true
    },

    async cancelRecord() {
      const token = JSON.parse(localStorage.getItem("token"))
      const _id = this.recordToCancel._id

      const response = await fetch(`${this.$store.state.server}/record/cancel-record-client`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${token}`
        },
        body: JSON.stringify({_id})
      })

      const json = await response.json()
      this.$store.commit("setServerMessage", json.message)

      this.dialog = false

      await this.loadRecordList()
    },

    async editHandler() {
      const token = JSON.parse(localStorage.getItem("token"))
      const firstName = this.firstName.trim()
      const lastName = this.lastName.trim()

      const response = await fetch(`${this.$store.state.server}/subscriber/change-fi`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${token}`
        },
        body: JSON.stringify({firstName, lastName})
      })

      const json = await response.json()
      this.$store.commit("setServerMessage", json.message)

      if (response.status === 200) {
        localStorage.setItem("firstName", JSON.stringify(firstName))
        localStorage.setItem("lastName", JSON.stringify(lastName))
      }

      this.dialog2 = false

      await this.loadRecordList()
    },

    timeIsOut(date, time) {
      return new Date(`${date}T${time}`) < new Date
    }
  },

  async mounted() {
    this.firstName = JSON.parse(localStorage.getItem("firstName"))
    this.lastName = JSON.parse(localStorage.getItem("lastName"))
    this.phone = JSON.parse(localStorage.getItem("phone"))
    await this.loadRecordList()
  }
}
</script>

<style scoped>

</style>
